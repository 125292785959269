.scheduleClassesBtn {
  text-transform: capitalize !important;
}
.breadBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.learnerScheduleCardBox {
  display: flex;
  justify-content: space-between;
}
.learnerScheduleCardBox2 {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.scheduleMainBox {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid #eceaea;
  margin-bottom: 0.75rem !important;
  transition: all 0.5s ease;
}
.scheduleMainBox:hover {
  background-color: #f2f2f2;
}
.scheduleMainBox .scheduleBtnBox {
  opacity: 0;
  transition: all ease 0.5s;
}
.scheduleMainBox:hover .scheduleBtnBox {
  visibility: visible;
  opacity: 1;
}

.sheduleDate,
.scheduleHeading {
  font-size: 0.875rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  color: #4a4545;
  margin-bottom: 0.5rem !important;
  text-transform: capitalize;
}
.agenda {
  font-size: 0.875rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  text-transform: capitalize;
}
.time {
  font-size: 0.75rem !important;
  font-weight: var(--font400) !important;
  font-family: var(--fontFamiljen);
}
.agendaBox {
  margin-bottom: 0.5rem;
}
.mentor {
  font-size: 0.75rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  text-transform: capitalize;
}
.learnerScheduleMainBox {
  margin-bottom: 0.5rem;
}
.cancelStatus {
  background-color: #fff;
  color: #333;
  font-family: var(--fontFamiljen);
  padding: 0.35rem 0.625rem;
  border-radius: 0.25rem;
  font-size: 12px !important;
  font-weight: var(--font400) !important;
}
.selectProgramBoxLearner {
  margin-top: 2rem;
}
/* ==================== */
.daysBox {
  width: 100%;
  background-color: #f5f4f4;
}
.datePickerBox {
  display: flex;
  align-items: end;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.selectedDateBox {
  background-color: #eceaea;
  width: 8rem;
  height: 100%;
  padding: 1.5rem 0rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.learnerSlotsBox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.agendaTextArea {
  height: 4.125rem !important;
}
.agendaInputBox {
  margin-bottom: 4.25rem;
}
.submitScheduleBox {
  text-align: right;
  padding-bottom: 1rem;
}
.datePicker input {
  outline: none;
  width: auto;
  padding: 1rem;
}
.dateSlots {
  font-size: 0.875rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  margin-bottom: 0.5rem !important;
}
.dateBox input {
  width: 0rem !important;
  background-color: #f2f2f2;
  height: 0.5rem;
  position: relative;
  border-radius: 0.25rem;
  visibility: hidden;
  padding: 16px 0px;
}
.skeletonBox{
  display: grid;
  width:100%;
  gap: 0.5rem;
  justify-content: space-between;
  grid-template-columns: repeat(5,1fr);
}
.cancelDuration{
  font-size: 0.875rem;
  
}