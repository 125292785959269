.deleteBtnStyle {
  width: 110px;
  height: 32px;
  outline: 0;
  border: none;
  display: flex;
  justify-content: center;
  padding: 10px 12px;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--fontMulish);
  font-weight: 400;
}
.modalStyles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 372px;
  height: 510px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 24;
}
.deleteMentorText {
  font-size: 14px;
  font-family: var(--fontFamiljen);
  font-weight: 400;
  margin-left: 2px;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
}
.deleteMentorHeading {
  font-size: 14px;
  font-family: var(--fontFamiljen);
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  color: var(--black);
}
.textHeading {
  font-size: 18px;
  font-family: var(--fontFamiljen);
  font-weight: 500;
  font-style: normal;
  line-height: 22px;
  color: var(--activityText);
}
.crossIconStyle {
  position: absolute;
  top: -30px;
  cursor: pointer;
  right: 0;
}
