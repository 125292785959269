.slotMainBox {
  height: 87vh;
  width: 100%;
}
.slotSubBox {
  width:80%;
  margin: 0 auto;
}
.scheduleMainBox {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid #eceaea;
  margin-bottom: 0.75rem !important;
  transition: all 0.5s ease;
}
.scheduleMainBox:hover {
  background-color: #f2f2f2;
}
.scheduleMainBox .scheduleBtnBox {
  opacity: 0;
  transition: all ease 0.5s;
}
.scheduleMainBox:hover .scheduleBtnBox {
  visibility: visible;
  opacity: 1;
}
.sheduleDate,
.scheduleHeading {
  font-size: 0.875rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  color: #4a4545;
  margin-bottom: 0.5rem !important;
  text-transform: capitalize;
  word-break: break-all;
}
.reScheduleBtn {
  width: 100%;
  text-align: right;
}
.scheduleHeading {
  color: #100f0f;
  margin-bottom: 0rem !important;
  word-break: break-all;
}
.scheduleTime,
.scheduleStudent {
  font-size: 12px !important;
  color: #4a4545 !important;
  margin-bottom: 0.75rem !important;
  text-transform: capitalize;
}
.scheduleStudent {
  margin-bottom: 0rem !important;
}
.scheduleStudentName {
  font-size: 12px !important;
  font-family: var(--fontFamiljen);
  font-weight: var(--font500) !important;
  color: #100f0f !important;
  text-transform: capitalize;
}
.scheduleBtnBox {
  display: flex;
  gap: 0.5rem;
}
.scheduleSubBox {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.scheduleBtnStyle {
  box-shadow: none !important;
  padding: 0.2rem 0.6rem !important;
  text-transform: capitalize !important;
  background-color: #fff;
  font-size: 0.875rem!important;
  border-radius: 4px;
}
/* ================== */
.reasonSelectBox {
  background-color: #f2f2f2;
  border-radius: 0.25rem;
}

/* ====================== */
.slotsHeadingBox {
  margin-bottom: 1.85rem;
  padding-top: 1rem;
}
.slotsHeadings {
  font-size: 2rem;
  font-family: var(--mulish);
  font-weight: var(--font800);
  color: #100f0f;
  margin: 0;
}
.subSlotHeading {
  font-size: 1.125rem;
  font-family: var(--fontFamiljen);
  font-weight: var(--font500);
  color: #696363;
}
/* ================== */
.slotsBox {
  width: 10rem;
  height: 2rem;
}
.slotsTimingList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  width: 100%;
}
.slotsBookBtn {
  font-family: var(--fontFamiljen) !important;
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: var(--font500) !important;
  box-shadow: none;
}
.bookBtnBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkSlots,.mentorDate {
  font-weight: var(--font400)!important;
  font-family: var(--fontFamiljen)!important;
  font-size: 0.875rem!important;
}
.mentorDate{margin-bottom: 0.5rem!important;}
