.dashboardHeading {
  padding: 1rem 0rem;
  padding-bottom: 0;
  color: #100f0f;
  font-family: var(--mulish) !important;
  font-weight: var(--font800) !important;
  font-size: 1.25rem !important;
}
.bubbleChartwebDash {
  width: 35rem;
  height: 17rem;
  margin: 0 auto;
}
/* ================== */
.dashboardDateSelectBox {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}
.dashboardSelect {
  width: 13rem;
}
.formControl {
  width: 100%;
  background-color: #f2f2f2;
}
.countBoxes {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
}
.bubblesCardsBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.chartsBox {
  width: 100%;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
}
/* ================== */
.bubbleCharBox {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 59% 40%;
  gap: 1rem;
  margin-bottom: 1rem;
}
/* ================ */
.upcomingMainBox {
  transition: all ease 0.5s;
  padding: 1rem;
  cursor: pointer;
}
.upcomingMainBox:hover {
  background-color: #f2f2f2;
}
.upcomingClassesBox {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}
.dashboardChartHeadings {
  font-size: 1rem;
  font-weight: var(--font600) !important;
  font-family: var(--fontFamiljen);
  color: #4a4545;
}
.dashboardChartSubHeadings {
  font-size: 0.75rem !important;
  font-weight: var(--font500) !important;
  font-family: var(--fontFamiljen);
  color: #100f0f;
}
.scheduleClassBox {
  text-align: right;
}
.DashboardSubHeading {
  color: #4a4545;
  font-weight: var(--font400);
  font-size: 0.75rem !important;
}
.OuterDashboardSubHeading {
  padding: 0.5rem 1rem;
  font-size: 0.75rem !important;
}
.dashboardChartHeadingsGap {
  font-size: 1rem;
  font-weight: var(--font600) !important;
  font-family: var(--fontFamiljen);
  color: #4a4545;
  margin-bottom: 1.75rem !important;
}
.overContenthidden {
  height: 100vh;
  min-height: 23rem;
  max-height: 23rem;
  overflow-y: scroll;
}
.upcomingScheduleMainBox {
  padding: 1rem 0rem 0rem 1rem;
}
.doughnutBoxStyle {
  width: 13rem;
  height: 100%;
  margin: 0 auto;
  margin-top: -40px;
  display: flex;
  align-items: center;
  position: relative;
}
/* ================= */
.webBarChart {
  width: 34rem;
  height: 17rem;
}
.horizontalBarBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.countProgramsBox {
  position: absolute;
  top: 8rem;
  left: 2.5rem;
  color: #000;
  text-align: center;
}
.totalPrograms {
  font-size: 0.875rem !important;
  font-family: var(--fontFamiljen);
  font-weight: var(--font500);
  color: #201e1e;
}
.numberOfPrograms {
  color: var(--themeOrange);
  font-weight: var(--font700);
}
.mainLearnerContainer {
  padding: 16px 0 16px 10px;
}
.mainLearnerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.learnerChipBoxes {
  /* position: relative; */
  display: flex;
  align-items: center;
  gap: 20px;
}
.learnerHeadingBox {
  display: flex;
  flex-direction: column;
}
.learnerNameText {
  color: var(--black);
  font-family: var(--fontMulish);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal;
}
.learnerDescText {
  color: var(--inerTextColor);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.chipMainBox {
  width: 150px;
  height: 47px;
  border-radius: 34px;
  border: 0.5px solid var(--black);
  background: var(--lightGray);
}
.learnerDashboardImage {
  position: absolute;
  left: -10px;
}
.textBox {
  margin-left: 45px;
}
.numberText {
  color: var(--black);
  font-family: var(--fontFamiljen);
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.qualityText {
  color: var(--emailTextForAssign);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.chipsInsideArr {
  position: relative;
}
.selectedProgramStyles {
  font-weight: 600 !important;
  height: 60px;
  font-size: 14px !important;
  font-family: var(--fontFamiljen);
  color: var(--activityText);
  width: 295px;
}
.selectProgramBox {
  margin-top: 48px;
}
.selectBox {
  height: 40px;
  background-color: var(--lightGray);
  width: 295px;
  font-family: var(--fontFamiljen);
  line-height: 18px;
  color: #000;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-top: 10px;
}
.selectedProgramHeading {
  color: var(--legendColor);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.mainHeaderBoxContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
}
.mainAboveBoxContainer {
  display: flex;
  gap: 20px;
  width: 100%;
  height: 200px;
}
.mainBelowBoxContainer {
  display: flex;
  gap: 20px;
  width: 100%;
  min-height: 350px;
}
.averageScroreContainer {
  width: 30%;
  border-radius: 8px;
  border: 1px solid var(--assignFadeBtn);
  padding: 16px;
}
.jsFundamContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 70%;
  border-radius: 8px;
  border: 1px solid var(--black);
  background-color: var(--orange-shade-p-10000, #130602);
}
.scheduleClassesBtn {
  text-transform: capitalize !important;
}
.overallContainer {
  width: 75%;
  border-radius: 8px;
  border: 1px solid var(--assignFadeBtn);
}
.upcomingContainer {
  width: 25%;
  border-radius: 8px;
  /* height: 305px; */
  border: 1px solid var(--assignFadeBtn);
}
.mainUpcomingBox {
  height: 290px;
  overflow: auto;
}
.avgScoreText {
  color: var(--activityText);
  font-family: var(--fontFamiljen);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.semiCircleGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.jsFundamentalBox {
  display: flex;
  gap: 12px;
  padding: 24px;
  padding-bottom: 0;
}
.jsBox {
  text-align: end;
  height: 48px;
  width: 48px;
  background: #fff;
  border: 0.503px solid var(--black);
  color: var(--themeOrange);
  border-radius: 7px;
}
.jsText {
  font-family: var(--fontMulish) !important;
  font-size: 21px !important;
  font-style: normal;
  font-weight: 800 !important;
  margin-top: 16px !important;
  margin-right: 7px !important;
  line-height: normal;
}
.jsfundamentalText {
  color: #fff;
  font-family: var(--fontMulish);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal;
}
.progressBarContainer {
  display: flex;
  flex-direction: column;
}
.graphBox {
  display: flex;
  justify-content: space-between;
  width: 200px;
  align-items: center;
  margin-top: 10px;
}
.moduleText,
.moduleNoTextText {
  color: var(--otpBg);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.bottomBorderLine {
  border: 1px solid var(--percentageTextColor);
  /* margin-top: 60px; */
}
.selectMentorBox {
  height: 40px;
  background-color: var(--lightGray);
  width: 13rem;
  font-family: var(--fontFamiljen);
  line-height: 18px;
  color: #000;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-top: 10px;
}
.selectedMentorStyles {
  font-weight: 600 !important;
  height: 37px;
  font-size: 14px !important;
  font-family: var(--fontFamiljen);
  color: var(--activityText);
  width: 210px;
}
.timeSpentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
}
.timeSpentText {
  color: var(--lightGray);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.spentBox {
  display: flex;
  gap: 12px;
  align-items: center;
}
.pickUpBox {
  display: flex;
  gap: 4px;
  align-items: center;
}
.pickLine {
  color: var(--themeOrange);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  cursor: pointer;
}
.back_arrow {
  cursor: pointer;
}
.upcomingMainContainer {
  padding: 16px 20px;
  color: var(--activityText);
  font-family: var(--fontFamiljen);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.todayHeading {
  color: var(--emailTextForAssign);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px 20px;
}
.classesContainer {
  min-height: 76px;
  padding: 12px 16px;
  background-color: #fff;
  cursor: pointer;
}
.classesContainer:hover {
  background-color: var(--otpBg);
}
.programsText {
  color: var(--black);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.mainMentorContainer {
  /* display: flex; */

  /* justify-content: space-between; */
}

.mentorsContainer {
  text-align: end;
}

.liveChip {
  height: 24px;
  border-radius: 24px;
  border: 1px solid var(--comparisionColor);
  background-color: var(--themeOrangeLight);
  font-size: 12px !important;
  font-family: var(--fontFamiljen);
  color: var(--themeOrange);
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 3px 8px 5px 8px;
}

.timingText {
  color: var(--activityText);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.mentorText {
  color: var(--black);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.mentorHeadingText {
  color: var(--activityText);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.todayDate {
  color: var(--emailTextForAssign);
  font-family: var(--fontFamiljen);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  padding: 16px 20px;
}
.nodataFound {
  color: var(--dashBoardEmptyText);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.overallText {
  color: var(--activityText);
  font-family: var(--fontFamiljen);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.upcomingClassesContainer {
  height: 185px;
  overflow: auto;
}
.dummyEmptyText {
  color: var(--dashBoardEmptyText);
  font-family: var(--fontFamiljen);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
